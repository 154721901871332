$(function() {
    var $autoDisplayLightbox = $('.lightbox-container.auto-display');

    var closeLightbox = (lightboxContainer) => {
        $('body').removeClass('overflow-none');
        lightboxContainer.removeClass('show fade');
        let iframe = lightboxContainer.find('iframe');
        if (iframe){
            let iframeSrc = iframe.attr('src');
            iframe.attr('src', iframeSrc);
        }
    }

    var openLightbox = (lightboxContainer) => {
        $('body').addClass('overflow-none');
        lightboxContainer.addClass('show fade');
    }

    if ($autoDisplayLightbox && $autoDisplayLightbox.length > 0) {
        $(window).on('load', function(){
            setTimeout(openLightbox($autoDisplayLightbox), 1000)
        });
    }

    // $('.lightbox-btn').on('click', function(e){
    //     e.preventDefault();
    //     debugger
    //     // var lightboxId = $(this).data('lightboxId');
    //     // var lightboxContainer = $(`div#lb-${lightboxId}`);
    //     var lightboxContainer = $(this).parents('.with-lightbox').children('.lightbox-container');
    //     openLightbox(lightboxContainer);
    // });

    // Close lightbox on click
    $('.lightbox-container', '.lightbox-container span.close-btn').on('click', function(){
        var lightboxContainer = $(this).parents('div.lightbox-container');
        closeLightbox(lightboxContainer)
    });

    $('.lightbox-container').on('click', function(){
        closeLightbox($(this))
    });
})