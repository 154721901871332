$(function() {
  const nav = $(".header");
  const dropdowns = $(".main").find(".dropdown");
  const submenus = $(".main").find(".submenu");
  const submenu = $(".main").find(".submenu-item");
  const searchBtn = $(".main").find(".search-btn");
  const searchBar = nav.find(".search-bar");
  const overlay = nav.find(".overlay");
  const hamburger = nav.find(".hamburger");
  const sidebar = $(".sidebar");
  const sidebarDropdowns = sidebar.find(".dropdown");
  const sidebarSubmenus = sidebar.find(".submenu");

  let arr = [];

  // Get height of submenus on load or resize
  $(window).on('resize', function() {
    arr = [];
    submenus.each(function() {
      arr.push($(this).outerHeight());
    });
  }).resize();

  // Hide sidebar and overlay on resize to large screen
  function toggleSidebar(query) {
    if (!query.matches) {
      sidebar.addClass("hide");
      overlay.addClass("d-none");
      $('body').removeClass('overflow-none');
    }
  }
  const query = window.matchMedia("(max-width: 1000px)")
  toggleSidebar(query)
  query.addEventListener('change', toggleSidebar)

  // Set height of navbar on dropdown hover or focus in
  dropdowns.each(function(index) {
    $(this).on('mouseover focusin click', function() {
      nav.css("height", `calc(92px + ${arr[index]}px)`);
      searchBar.addClass("transparent"); // Hide search bar if visible
    });
  });

  // Unset height of navbar on dropdown hover or focus out
  dropdowns.on('mouseout focusout', function() {
    nav.css("height", "");
  });

  // Remove focus on submenu on hover out
  submenu.on('mouseout', function() {
    submenu.blur();
  });

  // Toggle search bar display on search button click
  searchBtn.on('click keypress', function() {
    searchBar.toggleClass("transparent");
    nav.toggleClass("translucent");
  });

  // Toggle sidebar and overlay display on hamburger or overlay click
  hamburger.add(overlay).on('click', function() {
    sidebar.toggleClass('hide');
    overlay.toggleClass('d-none');
    $('body').toggleClass('overflow-none');
  });

  // Toggle sidebar menu dropdown on menu click
  sidebarSubmenus.addClass('submenu-none');
  sidebarDropdowns.on('click', function() {
    $(this).find(".submenu").toggleClass('submenu-none');
    $(this).find(".material-icons").toggleClass('rotate');
  });
});