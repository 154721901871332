$(function() {
  const close = $('.callout').find('.close')
  const clear = $('.search').find('.close')
  const searchField = $('.search').find('#query')
  const closeAlert = $('.alert').find('.close')

  // Close callout on click
  close.on('click', function(event) {
    $(event.target).parent().addClass('d-none')
  })

  // Clear search field on click
  clear.on('click', function() {
    searchField.val('').focus()
  })

  // Close flash messages on click
  closeAlert.on('click', function(event) {
    $(event.target).parent().addClass('d-none')
  })
})
