$(function () {
    var $lockedContent = $('.js-lock');
    var $modal = $('.js-modal');
    var $innerModal = $modal.find('.modal');

    var closeModal = () => {
        $('body').css('overflow', 'auto');
        $modal.one('animationend', () => $modal.removeClass('show'));
        $modal.removeClass('fade');
    };

    var openModal = (e) => {
        $('body').css('overflow', 'hidden');
        e.preventDefault();
        $modal.addClass('show fade');
        var $closeBtn = $modal.find('.close-btn');
        $closeBtn.one('click', closeModal);
    };

    $lockedContent.on('click', openModal);

    /* listen on click outside */
    $modal.on('click', closeModal);
    $innerModal.on('click', (e) => {
        e.stopPropagation();
    });
});
