$(function() {
  const shareBtn = $('.share')

  shareBtn.on('click', function(e) {
    e.stopPropagation()
    $(this).next('.share-buttons').toggleClass('d-none')
  })

  $('body').on('click', function() {
    $('.share-buttons').addClass('d-none')
  })
})
