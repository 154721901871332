$(function() {
  const copyContainer = $('.copy-container')

  copyContainer.each(function() {
    const more = $(this).find('.more')
    const less = $(this).find('.less')
    const copy = $(this).find('.copy')

    // Collapse copy if more than 5 lines
    if (copy.innerHeight() > 136) {
      copy.addClass('collapse')
      more.removeClass('transparent')
    }

    // Toggle copy display on click
    more.add(less).on('click', function() {
      more.add(less).toggleClass('transparent')
      copy.toggleClass('collapse')
    })
  })
})